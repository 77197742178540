import axios from 'axios';

/**
 * Can be used as interceptor in axios, to automatically inject the JWT Token to the
 * REST requests. 
 */
export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = window.localStorage.getItem("jwt");
        const path = new URL(config.url).pathname
        if(token && path != '/auth/local') {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });
}