import { createRouter, createWebHistory } from '@ionic/vue-router';
import Page1 from "./pages/Page1.vue";
import Login from "./pages/Login.vue";
import Home from "./mobile/pages/home.vue";
import Persons from "./mobile/pages/persons.vue";
import Vehicles from "./mobile/pages/vehicles.vue";
import Doors from "./mobile/pages/doors.vue";
import { isMobile } from 'mobile-device-detect';
import Profile from "@/mobile/pages/profile";

export const router = createRouter({
  
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "page1",
      component: Page1,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/app",
      name: "app",
      component: Home,
      children: [
        {
          path: '',
          redirect: '/app/persons',
        },
        {
          path: '/app/persons',
          component: Persons,
        },
        {
          path: "/app/login",
          name: "app-login",
          component: Login,
        },
        {
          path: '/app/vehicles',
          component: Vehicles,
        },
        {
          path: '/app/doors',
          component: Doors,
        },
        {
          path: '/app/profile',
          component: Profile,
        },
      ],
      linkActiveClass: 'active',
    }
  ],
});

router.beforeEach(function (to, from, next)  {
  console.log({to, from});
  const publicPages = ['/login', '/app/login'];
  const authRequired = !publicPages.includes(to.path);
  
  const isMob = isMobile;

  const loggedIn = localStorage.getItem('jwt');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if(isMob && !to.query.disableMobileDetection) {
    if(!to.fullPath.startsWith('/app')) {
      next('/app');
    } else {
      if (authRequired && !loggedIn) {
        next('/app/login');
      } else {
        next();
      }
    }
  }
  else {
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  }
});