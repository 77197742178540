
const SOCKET_OFFLINE = 'offline';
const SOCKET_ONLINE = 'online';
const RELOAD_TIMEOUT = 60 * 15; // --> 15 Minutes

const initialState = {
    socket: SOCKET_OFFLINE,
    socketOfflineSince: 0, 
}

export const connection = {
  namespaced: true,
  state: initialState,
  actions: {
    socketIsOffline(store) {
        console.log('Socket is now offline.')
        store.commit('updateSocketConnectionState', SOCKET_OFFLINE);
        store.commit('setSocketOfflineSince', new Date().getTime());
    },
    socketIsOnline(store) {
        console.log('Socket is now online.')
        store.commit('updateSocketConnectionState', SOCKET_ONLINE);
    },
    checkIfReloadNeeded(store) {
        let now = new Date().getTime() / 1000;
        let offline = store.state.socketOfflineSince / 1000
        let delta_t = now - offline;
        
        if(delta_t > RELOAD_TIMEOUT && store.state.socket === SOCKET_OFFLINE && store.state.socketOfflineSince > 0) {
          //location.reload();  
          console.log(`Δt: ${delta_t}`);
          console.log("Would have reloaded the page. \n But deactived for now. ")
        }
    }
  },
  mutations: {
    updateSocketConnectionState(state, connectionState) {
        state.socket = connectionState;
    },
    setSocketOfflineSince(state, date) {
        console.log(`offline since: ${date}`);
        state.socketOfflineSince = date;
    }
  }
};