import axios from 'axios';

class AuthService {

    async login(username, password) {

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/local`, {
            identifier: username,
            password: password
        });

        const { jwt, user } = await response.data

        window.localStorage.setItem('jwt', jwt)
        window.localStorage.setItem('user', JSON.stringify(user))

        return await response.data
    }
    
    logout() {
        window.localStorage.removeItem('jwt');
        window.localStorage.removeItem('user');
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for(let registration of registrations) {
             registration.unregister()
        } })
        document.location.assign(document.location.origin + "/")
    }

}

export default new AuthService