<template>
  <Card class="root" :class="vehicle.status">
    <template #title>
      {{ vehicle.name }}
      <div>
        <span
          class="voltage"
          v-if="
            vehicle.shelly &&
            vehicle.shelly.voltage > vehicle.shelly.charging_voltage &&
            vehicle.shelly.state === 'online'
          "
        >
          🔌
        </span>
        <span
          class="voltage"
          :class="{
            warning: vehicle.shelly.voltage < vehicle.shelly.warning_voltage,
            critical: vehicle.shelly.voltage < vehicle.shelly.critical_voltage,
          }"
          v-if="vehicle.shelly && vehicle.shelly.state === 'online'"
        >
          {{ vehicle.shelly.voltage }} V
        </span>
        <Button
            v-if="vehicle.status != 'STATUS_6'"
          label=""
          @click="sendNotificaion(vehicle)"
          icon="pi pi-bell"
          iconPos="right"
          class="p-button-sm p-button-raised p-button-warning"
        />
      </div>
    </template>
    <template #content >
      <span v-if="vehicle.status != 'STATUS_6'">
        <vehicle-position
            v-for="position in vehicle.positions"
            :key="position.name"
            :vehicle="vehicle"
            :position="position"
        />
      </span>
      <span v-if="vehicle.status == 'STATUS_6'">
        <Message :closable="false" severity="error">Nicht Einsatzbereit</Message>
      </span>

    </template>
    <template #footer> </template>
  </Card>
</template>

<script>
import VehiclePosition from "./VehiclePosition.vue";
import { mapActions } from "vuex";

export default {
  name: "Vehicle",
  components: {
    VehiclePosition,
  },
  props: {
    vehicle: Object,
  },
  methods: {
    ...mapActions({
      notifyAction: "vehicle/notify",
    }),
    sendNotificaion(vehicle) {
      this.notifyAction(vehicle);
    },
  },
};
</script>
<style lang="scss" scoped>

.STATUS_1 {
  box-shadow: 0px 0px 13px 3px var(--blue-800);
}
.STATUS_2 {
  box-shadow: 0px 0px 13px 3px var(--green-800);
}
.STATUS_3 {
  box-shadow: 0px 0px 13px 3px var(--yellow-800);
}

.STATUS_4,
.STATUS_5,
.STATUS_6,
.STATUS_7,
.STATUS_F,
{
  box-shadow: 0px 0px 13px 3px var(--red-800);
}

.STATUS_8, .STATUS_6, {
  background: rgba(255,255,255,0.8);
}

</style>

<style lang="scss">

.STATUS_2 {
  box-shadow: 0px 0px 13px 3px green;
}

.voltage {
  font-weight: normal;
  font-size: 1rem;
  text-align: right;
  line-height: 2rem;

  &.warning {
    color: orange;
  }
  &.critical {
    animation: blinkingText 2s infinite;
  }
}

@keyframes blinkingText {
  0% {
    color: transparent;
  }
  49% {
    color: red;
  }
  50% {
    color: red;
  }
  99% {
    color: red;
  }
  100% {
    color: transparent;
  }
}

.root {
  max-width: 350px;
  min-width: 300px;
  margin: 16px;
}

.p-card-body {
  width: 100%;
}
.p-card-title {
  display: flex;
  justify-content: space-between;
}
</style>