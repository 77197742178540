import axios from "axios";
import AuthService from "@/services/auth.service";

const initialState = {
    globalPositions: {}
}

export const globalPositions = {
    namespaced: true,
    state: initialState,
    getters: {
        getGlobalPositions: (state) => {
            return state.globalPositions;
        }
    },
    actions: {
        async getGlobalPositions(state) {
            try {
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}/global-positions`);
                state.commit('addGlobalPosition', data)
            } catch (error) {
                if (error.response.status == 401) {
                    AuthService.logout()
                    this.router.push('/login');
                }
                if (error.response.status == 403) {
                    console.error("Not able to get GlobalPosition. Maybe not Logged in?");
                }
            }
        },
        async updateRemoteGlobalPositions(state) {
            return await axios.put(`${process.env.VUE_APP_API_URL}/global-positions`, state.getters.getGlobalPositions);
        },
        async notify(state, position) {
            try {
                let response = await axios.post(`${process.env.VUE_APP_API_URL}/global-positions/notify`, position);
                this.$toast.add({severity:'success', summary: 'Benachrichtigung versendet', detail:'Die Einteilung wurde erfolgreich an die Mannschaft per aPager verendet', life: 3000});
                return await response
            } catch(error) {
                this.$toast.add({severity:'error', summary: 'Benachrichtigung NICHT versendet', detail:'Es ist ein Fehler beim Versenden der Benachrichtigung aufgetreten', life: 3000});
                return error;
            }

        },
        /**
         *
         * @param {*} state
         * @param {*} payload {vehicle: vehicleObject, position: positionObject}
         */
        clearPosition(state, payload) {
            let position = state.getters.getGlobalPositions.positions.find(position => position.name === payload.position)
            console.log(position)
            position.person = {};
            state.dispatch('updateRemoteGlobalPositions');        },
        /**
         *
         * @param {*} state
         * @param {*} payload {person: Person Object, position: <String> Positon on Vehicle, vehicle: <String> of vehicle Name}
         */
        assignPersonToPosition(state, payload) {
            let position = state.getters.getGlobalPositions.positions.find(position => position.name === payload.position)
            position.person = payload.person;
            state.dispatch('updateRemoteGlobalPositions');
        },
        ioUpdateGlobalPosition(state, data) {
            state.commit('addGlobalPosition', data);
        }
    },
    mutations: {
        addGlobalPosition(state, data) {
            state.globalPositions = data;
        },
    }
};