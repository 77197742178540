<template>
  <div
    class="vehicle-position"
    v-bind:class="{ hasPerson: position.person?.name }"
    @click="toggle"
  >
    <div class="position-name">{{ position.name }}</div>
    <div class="position-person">
      <div class="position-person-name">
        <person-display :show-functions="true" v-if="position.person"  :person="position.person" />
      </div>
      <div class="position-person-clear">
        <Button
          v-if="position.person?.name"
          @click.stop="clear"
          icon="pi pi-trash"
          class="p-button-sm p-button-icon-only p-button-danger p-button-text"
          label=""
        />
      </div>
    </div>
  </div>

   <Dialog :header="position.name + ' ' +vehicle.name"
           v-model:visible="display"
           :dismissableMask="true"
           :modal="true"
           :draggable="false">
    <TabView :lazy="true">
      <TabPanel header="Qualifiziert">
        <Accordion :multiple="false" :activeIndex="getActiveTabIndex()" :lazy="true">
          <AccordionTab>
            <template #header>
              <i class="pi pi-building"></i>
              <span class="header">Anwesend</span>
            </template>
            <div
              @click="select(person)"
              v-for="person in qualifiedPersonsOnSite"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i class="pi pi-car"></i>
              <span class="header">Auf Anfahrt</span>
            </template>

            <div
              @click="select(person)"
              v-for="person in qualifiedPersonsOnApproach"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i class="pi pi-bed"></i>
              <span class="header">Alle Qualifizierten</span>
            </template>
            <div
              @click="select(person)"
              v-for="person in qualifiedPersonsOffSite"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
        </Accordion>
      </TabPanel>
      <TabPanel header="Alle">
        <Accordion :multiple="false" :activeIndex="0">
          <AccordionTab>
            <template #header>
              <i class="pi pi-building"></i>
              <span class="header">Anwesend</span>
            </template>
            <div
              @click="select(person)"
              v-for="person in getPersonsOnSite"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i class="pi pi-car"></i>
              <span class="header">Auf Anfahrt</span>
            </template>
            <div
              @click="select(person)"
              v-for="person in getPersonsOnApproach"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i class="pi pi-bed"></i>
              <span class="header">Alle</span>
            </template>
            <div
              @click="select(person)"
              v-for="person in getPersonsOffSite"
              :key="person"
              class="list-item"
            >
              <person-display :show-functions="true" :showPosition="true" :highlightWithoutPosition="true" :person="person" />
            </div>
          </AccordionTab>
        </Accordion>
      </TabPanel>
    </TabView>
   </Dialog>
  <!--</OverlayPanel>-->
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PersonDisplay from "./PersonDisplay.vue";

export default {
  name: "VehiclePosition",
  components: {
    PersonDisplay,
  },
  props: {
    vehicle: Object,
    position: Object,
  },
  data() {
    return {
      display: false
    }
  },
  methods: {
    ...mapActions({
      clearPosition: "vehicle/clearPosition",
      assignPersonToPosition: "vehicle/assignPersonToPosition",
    }),
    clear() {
      this.clearPosition({ vehicle: this.vehicle, position: this.position });
    },
    toggle() {
      this.display = !this.display;
    },
    select(person) {
      let payload = {
        person: person,
        vehicle: this.vehicle.name,
        position: this.position.name,
      };
      this.assignPersonToPosition(payload);
      this.display = !this.display;
    },
    getActiveTabIndex() {
      if(this.qualifiedPersonsOnSite.length > 0) {
        return 0;
      } else {
        if(this.qualifiedPersonsOnApproach.length > 0) {
          return 1;
        } else {
          return 2;
        }
      }

    }
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons.persons,
    }),
    ...mapGetters({
      getPostionOfPerson: "vehicle/getPostionOfPerson",
      getPersonsByLocation: "persons/getPersonsByLocation",
      getQualifiedPersons: "persons/getQualifiedPersons",
      getPersonsOnSite: "persons/getPersonsOnSite",
      getPersonsOffSite: "persons/getPersonsOffSite",
      getPersonsOnApproach: "persons/getPersonsOnApproach",
    }),
    qualifiedPersonsOnSite() {
      return this.getQualifiedPersons(this.position.requires, "ON_SITE");
    },
    qualifiedPersonsOnApproach() {
      return this.getQualifiedPersons(this.position.requires, "ON_APPROACH");
    },
    qualifiedPersonsOffSite() {
      return this.getQualifiedPersons(this.position.requires, "OFF_SITE");
    },

  },
};
</script>
<style lang="scss" >
.position {
  margin-bottom: 1rem;
}
.header {
  margin-left: 0.3rem;
}
.vehicle-position {
  border: 1px var(--border-style, dashed) var(--border-color, #ccc);
  min-height: 75px;
  min-width: 100%;
  border-radius: 3px;
  margin-bottom: 0.4rem;
  &:hover {
    cursor: pointer;
  }

  &.hasPerson {
    --border-color: var(--green-300);
    --border-style: solid;
    --header-background: var(--green-300);
  }

  .position-name {
    padding: 5px;
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    text-transform: none;
    background-color: var(--header-background, #ccc);
  }

  .position-person {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    .position-person-name {
      min-height: 100%;
      flex-grow: 1;
    }
  }
}

.list-item {
  border-bottom: 1px solid #333;
  padding: 5px 0 5px 0;
  &:hover {
    cursor: pointer;
  }
  &:only-child {
    border-bottom: none;
  }
}
</style>