import axios from "axios";
import AuthService from "../services/auth.service"
const initialState = {
    persons: []
}
const availibilityWheight = new Map([
    ['AVAILABLE', 3],
    ['TEMP_NOT_AVAILABLE', 2],
    ['NOT_AVAILABLE', 1],
]);
export const persons = {
    namespaced: true,
    state: initialState,
    getters: {
        getPersonsByLocation: (state, getters, rootState, rootGetters) => (location, onlyPersonsWithoutPosition) => {
            if(onlyPersonsWithoutPosition === undefined) {
                onlyPersonsWithoutPosition = false;
            }
            if(location === "ON_SITE") {
                return state.persons.sort((a, b) => {
                    return availibilityWheight.get(b.state) - availibilityWheight.get(a.state);
                })
                .filter((person) => person.location === location || person.location === "SPORT" || person.location === "SIWA")
                .filter((person) => {
                    let positionSearchResult = rootGetters['vehicle/getPostionOfPerson'](person.name);
                    if(onlyPersonsWithoutPosition) {
                        return positionSearchResult.iPosition < 0;
                    } else {
                        return true;
                    }
                })
            }
            else
                return state.persons.filter((person) => person.location === location)
        },
        getPersonsOnSite: (state, getters,) => {return getters.getPersonsByLocation('ON_SITE')},
        getPersonsOffSite: (state, getters,) => {return getters.getPersonsByLocation('OFF_SITE')},
        getPersonsOnApproach: (state, getters,) => {return getters.getPersonsByLocation('ON_APPROACH')},
        getPersonsSport: (state, getters,) => {return getters.getPersonsByLocation('SPORT')},
        getPersonsSiWa: (state, getters,) => {return getters.getPersonsByLocation('SIWA')},
        /**
         * Returns a filtered subset of persons that match a certain set of Qualifications.
         *
         * Qualifications should be passed as a String:
         *
         * oneOffQualifications: "TM, TF, ZF" - A person needs to have either one of the functions.
         *
         * allOffQualifications: "MA+GW-Log" - A person than need to have Functions MA AND GW-Log
         *
         *
         * @param qualifications persons with at leas one of these Qualifications.
         * @param allOffQualifications persons with all of these Qualifications.
         * @returns any[] Array of persons
         */
        getQualifiedPersons: (state, getters) => (qualifications, location) => {
            console.log("getQualifiedPersons(" + qualifications+", " + location +");");
            let oneOffQualifications = qualifications
                // Remove Whitespaces
                .replace(/\s+/g, "")
                .split(",");

            // Check if Position requires multiple qualifications in combination:
            let qualificationSets = [];
            oneOffQualifications.forEach((qualification) => {
                // Detect if the given qualification is a Qualification Set:
                // "GW-Log+TF" -> ['GW-Log', 'TF']
                if(qualification.indexOf('+') >= 0) {
                    let needs = qualification.split('+');
                    qualificationSets.push(needs);
                }
            });

            let personsByLocation = [];

            switch (location) {

                case "ON_SITE": personsByLocation = getters.getPersonsOnSite;
                break;
                case "ON_APPROACH": personsByLocation = getters.getPersonsOnApproach;
                break;
                case "OFF_SITE": personsByLocation = getters.getPersonsOffSite;
                break;
                default: personsByLocation = getters.getPersonsByLocation(location, false);
                break;

            }





            return personsByLocation.filter((person) => {
                let qualificationsOfPerson;
                try {
                    qualificationsOfPerson = person.functions.split(";");
                } catch (e) {
                    qualificationsOfPerson = [];
                }


                if (oneOffQualifications.length > 0 && oneOffQualifications.some((r) => qualificationsOfPerson.indexOf(r) >= 0)) {
                   return true
                }

                if(qualificationSets.length > 0 && qualificationSets.some(
                    (qualificationSets) => {
                        // qulificationSet ==> ['GW-Log', 'TF']
                        return qualificationSets.every(
                            (qualification) => qualificationsOfPerson.indexOf(qualification) >= 0
                        )
                    }
                ))
                {
                    return true
                }
                return false;
            });
        }
    },
    actions: {
        async getPersons(state) {
            try {
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}/persons/`);
                state.commit('addPersons', data);
            } catch (error) {
                if (error.response.status === 401) {
                    AuthService.logout()
                    this.router.push('/login');
                }
                if (error.response.status === 403) {
                    console.error("Not able to get persons. Maybe not Logged in?");
                }
            }
        },
        resetAllPositions(state) {
            let personsToReset = state.getters.getPersonsByLocation("ON_SITE").concat(
                state.getters.getPersonsByLocation("ON_APPROACH")
            );
            personsToReset.forEach((person) => {
                state.dispatch('setLocation',{
                    person: person,
                    location: 'OFF_SITE'
                });
            })
        },
        /**
        * 
        * @param {*} state 
        * @param {*} payload {person: Person Objeckt, location: <String> ["ON_SITE" | "OFF_SITE" | "ON_APPROACH"]}
        */
        setLocation(state, payload) {
            let person = payload.person;
            person.location = payload.location;
            state.dispatch("updateRemotePerson", person)
        },
        ioUpdatePerson(state, person) {
            state.commit("updatePerson", person);
        },
        ioDeletePerson(state, person) {
            state.commit("removePerson", person);
        },
        async updateRemotePerson(state, person) {
            return await axios.put(`${process.env.VUE_APP_API_URL}/persons/${person.id}`, person);
        }
    },
    mutations: {
        addPersons(state, persons) {
            state.persons = persons.sort((a, b) => a.name.localeCompare(b.name));
        },
        updatePerson(state, person) {
            let iPerson = state.persons.findIndex(p => p.id === person.id);
            state.persons[iPerson] = person;
        },
        removePerson(state, person) {
            let iPerson = state.persons.findIndex(p => p.id === person.id);
            state.persons.splice(iPerson, 1);
        }
    }
};