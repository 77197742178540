<template>

  <ion-page>
    <form autocomplete="off" >
      <div class="p-grid p-fluid">
        <div class="md:col-offset-4 col-12 md:col-4">
          <Card>
            <template #header>
              <div v-if="error">
                <Message severity="error">{{ errorMsg }}</Message>
              </div>
            </template>
            <template #title>Anmelden</template>
            <template #content>
              <div class="field">
                <label for="username">Benutzername</label>
                <InputText id="username" v-model="username" autoComplete='none' autocorrect="off" autocapitalize="none"/>
              </div>
              <div class="field">
                <label for="password">Passwort</label>
                <Password
                    id="password"
                    autoComplete='current-password'
                    v-model="password"
                    :weakLabel="'false'"
                    :toggleMask="true"
                    :feedback="false"
                />
              </div>
              <div class="field">
                <Button type="submit" label="Login" @click="login($event)" />
              </div>
            </template>
            <template #footer> </template>
          </Card>
        </div>
      </div>
      <div id="login"></div>
    </form>
  </ion-page>
</template>

<script>
import {IonPage} from "@ionic/vue";
import AuthService from "../services/auth.service";
import {useIonRouter} from "@ionic/vue";

export default {
  name: "Login",
  components: {
    IonPage,
  },
  setup() {
    const ionRouter = useIonRouter();
    return {
      ionRouter
    }
  },
  data() {
    return {
      username: "",
      password: "",
      error: false,
      errorMsg: `An error occurred, please try again`,
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();
      try {
        await AuthService.login(this.username, this.password);
      } catch (error) {
        this.error = true;
        console.error(error);
        this.password = "";
      }
      this.ionRouter.navigate('/', "root", "pop");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";
</style>