<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Fahrzeuge</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-card v-for="truck in vehicles" :key="truck.name" :class="truck.status">
        <ion-card-header>

          <ion-card-title>
            {{ truck.name }}
            <ion-button size="small" color="warning" @click="sendNotificaion(truck)"><i class="pi pi-bell"></i></ion-button>
          </ion-card-title>


        </ion-card-header>
        <ion-card-content>
          <ion-accordion-group>
            <ion-accordion value="positions">
              <ion-item slot="header">
                <ion-label>Einteilung</ion-label>
              </ion-item>

              <ion-list slot="content">
                
         
                   <vehicle-position
                        v-for="position in truck.positions"
                        :key="position.name"
                        :vehicle="truck"
                        :position="position"
                      />
                
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonCardContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import VehiclePosition from "../../components/VehiclePosition.vue";

export default {
  name: "Vehicles",
  components: {
    VehiclePosition,
    IonContent,
    IonAccordion,
    IonAccordionGroup,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      persons: (state) => state.persons.persons,
    }),
  },
  methods: {
    ...mapActions({
      getVehicles: "vehicle/getVehicles",
      notifyAction: "vehicle/notify",
    }),
    sendNotificaion(vehicle) {
      this.notifyAction(vehicle);
    },
  },
  mounted() {
    this.getVehicles();
  },
};
</script>

<style lang="scss">

.STATUS_1 {
  box-shadow: 0px 0px 13px 3px var(--blue-800);
}
.STATUS_2 {
  box-shadow: 0px 0px 13px 3px var(--green-800);
}
.STATUS_3 {
  box-shadow: 0px 0px 13px 3px var(--yellow-800);
}

.STATUS_4,
.STATUS_5,
.STATUS_6,
.STATUS_7,
.STATUS_F,
{
  box-shadow: 0px 0px 13px 3px var(--red-800);
}

.STATUS_8, .STATUS_6, {
  background: rgba(255,255,255,0.8);
}

.p-overlaypanel .p-overlaypanel-close {
  right: 2rem;
}
ion-card {
  margin-inline: 0;
}
ion-card-title {
  display: flex;
  justify-content: space-between;
}
</style>
