import axios from "axios";
import AuthService from "@/services/auth.service";

const initialState = {
    ccu: []
}
export const ccu = {
    namespaced: true,
    state: initialState,
    getters: {

    },
    actions: {
        async getCCU(state) {
            try {
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}/ccu-jack-mqtt-subscriptions/`);
                state.commit('updateCCU', data);
            } catch (error) {
                if (error.response.status == 401) {
                    AuthService.logout()
                    this.router.push('/login');
                }
                if (error.response.status == 403) {
                    console.error("Not able to get ccu controllers. Maybe not Logged in?");
                }
            }
        },

        updteCCU(state, ccu) {
            state.commit('updateCCU', ccu);
        }

    },
    mutations: {
        updateCCU(state, ccu) {
            state.ccu = ccu;
            for (let i = 0; i < state.ccu.length; i++) {
                const element = state.ccu[i];
                element.controls = element.controls.sort((a, b) => {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
    }
};