import { createStore } from "vuex";
import { auth } from "./auth.module";
import { vehicle } from "./vehicle.module";
import { persons } from "./persons.module";
import { connection } from './connection.module';
import { ccu } from './ccu.module';
import { globalPositions } from "@/store/global-positions.module";

const store = createStore({
  modules: {
    auth,
    vehicle,
    persons,
    connection,
    ccu,
    globalPositions,
  }
});

export default store;