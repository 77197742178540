<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Profil</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <Button type="submit" @click="logout()">Logout</Button>

    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import AuthService from "@/services/auth.service";
import Button from "primevue/button";

export default {
  name: "Profile",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    Button,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      AuthService.logout();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>