<template>
<ion-app>
    <ion-page>
    <ion-content>
      <ion-tabs>
        <ion-router-outlet mode="ios"></ion-router-outlet>
        <ion-tab-bar>
          <ion-tab-button tab="tab1" href="/app/persons">
            <ion-icon :icon="peopleOutline" />
            <ion-label>Anwesenheit</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab2" href="/app/vehicles">
            <ion-icon :icon="busOutline" />
            <ion-label>Einteilung</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab4" href="/app/profile">
            <ion-icon :icon="personOutline" />
            <ion-label>Profil</ion-label>
          </ion-tab-button>

        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
  </ion-app>
</template>
<script>

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Owptional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonApp,
  } from '@ionic/vue';
import { codeWorkingOutline, busOutline, peopleOutline, personOutline } from 'ionicons/icons';
export default {
  name: "Home",
  components: {
    IonContent,
      IonLabel,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonIcon,
      IonPage,
      IonRouterOutlet,
      IonApp,
  },
  data() {
    return {
      
    };
  },
  setup() {
      return {
        peopleOutline,
        codeWorkingOutline,
        busOutline,
        personOutline,
      };
    },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>