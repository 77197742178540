<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Anwesenheit</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-segment
          @ionChange="segmentChanged($event)"
          :value="segmentFilterValue"
        >
          <ion-segment-button value="OFF_SITE">
            <ion-label>Abwesend</ion-label>
          </ion-segment-button>
          <ion-segment-button value="ON_APPROACH">
            <ion-label>Anfahrt ({{ getPersonsByLocation('ON_APPROACH').length }})</ion-label>
          </ion-segment-button>
          <ion-segment-button value="ON_SITE">
            <ion-label>Anwesend ({{ getPersonsByLocation('ON_SITE').length }})</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-list>
        <availibility-control
          v-for="person in getPersonsByLocation(segmentFilterValue)"
          :person="person"
          :key="person.id"
        />
      </ion-list>
      <ion-list v-if="segmentFilterValue == 'ON_SITE'">
          <ion-list-header >
          <ion-label>Sicherheitswachdienst</ion-label>
        </ion-list-header>
        <availibility-control
          v-for="person in getPersonsByLocation('SIWA')"
          :person="person"
          :key="person.id"
        />
         <ion-list-header >
          <ion-label>Sport</ion-label>
        </ion-list-header>
        <availibility-control
          v-for="person in getPersonsByLocation('SPORT')"
          :person="person"
          :key="person.id"
        />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonList,
  IonListHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import AvailibilityControl from "../../components/AvailibilityControl.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Persons",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonListHeader,
    AvailibilityControl,
  },
  methods: {
    ...mapActions({
      getPersons: "persons/getPersons",
    }),
    segmentChanged(ev) {
      let value = ev.detail.value;
      this.segmentFilterValue = value;
    },
  },
  data() {
    return {
      segmentFilterValue: "OFF_SITE",
    };
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons.persons,
    }),
    ...mapGetters({
      getPersonsByLocation: "persons/getPersonsByLocation",
    }),
  },
  mounted() {
    this.getPersons();
  },
};
</script>

<style lang="scss" scoped>
</style>