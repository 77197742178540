<template>
  <div class="availibility-control">
    <person-display :show-functions="true" :person="person" :showPosition="true" :show-availability="true" />
    <span class="p-buttonset">
      <SplitButton @click="setOffSite(person)" v-if="person.location === 'SPORT'" class="p-button-sm p-button-outlined" icon="pi pi-power-off" :model="items"></SplitButton>
      <SplitButton @click="setOffSite(person)" v-if="person.location === 'SIWA'" class="p-button-sm p-button-outlined" icon="pi pi-power-off" :model="items"></SplitButton>
      <SplitButton @click="setOffSite(person)" v-if="person.location === 'ON_SITE'" class="p-button-sm p-button-outlined" icon="pi pi-power-off" :model="items"></SplitButton>
      <SplitButton @click="setOnSite(person)" v-if="person.location === 'ON_APPROACH'" label="" class="p-button-sm p-button-outlined" icon="pi pi-building" :model="items"></SplitButton>
      <SplitButton @click="setOnAppoach(person)" v-if="person.location === 'OFF_SITE'" label="" class="p-button-sm p-button-outlined" icon="pi pi-car" :model="items"></SplitButton>
    </span>
  </div>
</template>

<script>
import PersonDisplay from "./PersonDisplay.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AvailibilityControl",
  components: {
    PersonDisplay,
  },
  props: {
    person: Object,
  },
  data() {
		return {
			items: [
				{
					label: 'Anwesend',
					icon: 'pi pi-building',
					command: () => {
						this.setOnSite(this.person);
					}
				},
        {
					label: 'Anfahrt',
					icon: 'pi pi-car',
					command: () => {
						this.setOnAppoach(this.person);
					}
				},
				{
					label: 'Abwesend',
					icon: 'pi pi-power-off',
					command: () => {
						this.setOffSite(this.person);
					}
				},
				{
					label: 'Sport',
					icon: 'pi pi-heart-fill',
					command: () => {
						this.setSport(this.person);
					}
				},
				{
					label: 'SiWa',
					icon: 'pi pi-ticket',
					command: () => {
						this.setSiWa(this.person);
					}
				},
			]
		}
	},
  methods: {
    ...mapActions({
      setLocation: "persons/setLocation",
    }),
    setOnSite(person) {
      this.setLocation({person: person, location: "ON_SITE"});
    },
    setOnAppoach(person) {
      this.setLocation({person: person, location: "ON_APPROACH"});
    },
    setOffSite(person) {
      this.setLocation({person: person, location: "OFF_SITE"});
    },
    setSport(person) {
      this.setLocation({person: person, location: "SPORT"});
    },
    setSiWa(person) {
      this.setLocation({person: person, location: "SIWA"});
    }
  },
  computed: {
    ...mapGetters({
      getPostionOfPerson: "vehicle/getPostionOfPerson",
    }),
  },
};
</script>

<style lang="scss">
.availibility-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-top: 5px;

  .p-splitbutton.p-component.p-button-sm.p-button-outlined,
  .p-button.p-button-sm {
    font-size: 0.6rem;
    padding: 0.3rem 0.4rem;
  }
  .p-button.p-button-icon-only {
    width: 2rem;
    padding: 0.3rem 0.4rem;
    font-size: 0.6rem;
  }
}
</style>