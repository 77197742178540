<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Torsteuerung</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">Die Torsteuerung ist noch nicht verfügbar.</ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  name: "Doors",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>