<template>
  <div v-if="$isMobile()">
    <ion-app>
      <ion-router-outlet />
    </ion-app>
  </div>
  <div id="app1" v-else>
    <ConfirmDialog></ConfirmDialog>
    <Toast position="top-center" />
    <Toast position="bottom-center" group="bc">
      <template #message="slotProps">
        <div class="flex flex-column">
          <div class="text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>{{slotProps.message.summary}}</h4>
            <p>{{slotProps.message.detail}}</p>
          </div>
          <div class="grid p-fluid">
            <div class="col-6">
              <Button class="p-button-success" label="Ja" @click="onConfirm"></Button>
            </div>
            <div class="col-6">
              <Button class="p-button-secondary" label="Nein" @click="onReject"></Button>
            </div>
          </div>
        </div>
      </template>
    </Toast>
    <Menubar :model="items" :class="connection">
      <template #start> </template>
      <template #end>
        <div class="menu-end">
          <Button

              label=""
              @click="sendNotificaion()"
              icon="pi pi-bell"
              iconPos="right"
              class="p-button-sm p-button-raised p-button-warning"
          />
          <global-position
              v-for="position in globalPositions.positions"
              :key="position.name"
              :vehicle="vehicle"
              :position="position"
          />
          <Button type="button" icon="pi pi-cog" @click="toggle" />
          <Menu ref="menu" :model="menuitems" :popup="true" />
        </div>
      </template>
    </Menubar>
    <ion-router-outlet id="desktop"></ion-router-outlet>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import AuthService from "./services/auth.service";
import io from "socket.io-client";
import GlobalPosition from "@/components/GlobalPosition";


export default {
  name: "App",
  components: {
    Menubar,
    Menu,
    Button,
    IonApp,
    IonRouterOutlet,
    GlobalPosition,
  },
  computed: {
    ...mapActions({
      clearAllPositions: 'vehicle/clearAllPositions',
    }),
    ...mapState({
      connection: (state) => state.connection.socket,
      globalPositions: (state) => state.globalPositions.globalPositions,
    }),
  },
  data() {
    return {
      registration: null,
      updateExists: false,
      items: [{ label: "Einteilungstafel", icon: "pi pi-fw pi-home", to: "/" }],
      menuitems: [
        {
          label: "Ansicht",
          items: [
            {
              label: "Vollbild",
              icon: "pi pi-window-maximize",
              command: () => {
                if (!document.fullscreenElement) {
                  let b = document.getElementsByTagName("html")[0];
                  b.requestFullscreen();
                } else {
                  document.exitFullscreen();
                }
              },
            },
            {
              label: "Reload",
              icon: "pi pi-refresh",
              command: () => {
                navigator.serviceWorker
                  .getRegistrations()
                  .then(function (registrations) {
                    for (let registration of registrations) {
                      registration.unregister();
                    }
                  });
                window.location.reload();
              },
            },
          ],
        },
        {
          label: "Anwendung",
          items: [
            {
              label: "Einteilung zurücksetzen",
              icon: "pi pi-undo",
              command: () => {
                let self = this;
                this.$confirm.require({
                  header: "Achtung!",
                  message: "Es werden alle Einteilungen und Anwesenheiten zurückgesetzt!",
                  acceptLabel: "Ja, alles zurücksetzten",
                  rejectLabel: "Abbrechen",
                  acceptClass: "p-button-danger",
                  icon: "pi pi-exclamation-triangle",
                  defaultFocus: "reject",
                  accept: () => {
                    self.handleResetEverything();
                  },
                  reject: () => {
                    // Do nothing.
                  },
                });
              },
            },
            {
              label: "Logout",
              icon: "pi pi-power-off",
              command: () => {
                AuthService.logout();
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getPersons: "persons/getPersons",
      getVehicles: "vehicle/getVehicles",
      getCCU: "ccu/getCCU",
      ioUpdatePerson: "persons/ioUpdatePerson",
      ioDeletePerson: "persons/ioDeletePerson",
      ioUpdateVehicle: "vehicle/ioUpdateVehicle",
      ioUpdateGlobalPosition: "globalPositions/ioUpdateGlobalPosition",
      socketIsOffline: "connection/socketIsOffline",
      socketIsOnline: "connection/socketIsOnline",
      getGlobalPositions: "globalPositions/getGlobalPositions",
      globalPositionNotifyAction: "globalPositions/notify",
    }),
    sendNotificaion() {
      this.globalPositionNotifyAction({});
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.$toast.add({severity: 'warn', summary: 'Update verfügbar', detail: 'Einteilungstafel jetzt auf die neuste Version aktualisieren?', group: 'bc'});
      this.updateExists = true
    },
    refreshApp() {
      console.log("Update App triggered");
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onConfirm() {
      this.refreshApp();
      this.$toast.removeGroup('bc');
    },
    onReject() {
      this.$toast.removeGroup('bc');
    },
    handleResetEverything() {
      this.$store.dispatch('vehicle/clearAllPositions')
      this.$store.dispatch('persons/resetAllPositions')
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
  created() {
    console.log("Created Called.");
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    });
  },
  mounted() {
    this.getPersons();
    this.getGlobalPositions();
    this.getCCU();
    this.getVehicles();
    // TODO: Move into seperate State module, or Service.
    const API_URL = process.env.VUE_APP_API_URL;
    this.socket = io.connect(API_URL);
    this.socket.on("connect", () => {
      this.socketIsOnline();
      this.socket.emit("subscribe", "vehicles");
      this.socket.emit("subscribe", "persons");
      this.socket.emit("subscribe", "global-positions");
      // As soon as the socket is (re)connected, we try to get the latest version of vehicles and persons
      this.getPersons();
      this.getVehicles();
      this.getGlobalPositions();
    });

    this.socket.on("create", async (data) => {
      //do something
      this.console.log(data);
    });
    this.socket.on("update", (data) => {
      // do something
      data = JSON.parse(data);
      switch (data.identity) {
        case "persons":
          this.ioUpdatePerson(data);
          break;
        case "vehicles":
          this.ioUpdateVehicle(data);
          break;
        case "global-positions":
          this.ioUpdateGlobalPosition(data);
          break;
        default:
          break;
      }
    });
    this.socket.on("delete", (data) => {
      // do something
      console.log("DELETE");
      console.log(data);
      data = JSON.parse(data);
      switch (data.identity) {
        case "persons":
          this.ioDeletePerson(data);
          break;
        case "global-positions":
          this.ioUpdateGlobalPosition(data);
          break;
        default:
          break;
      }
    });
    this.socket.on("disconnect", (reason) => {
      this.socketIsOffline();
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        this.socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
    // END of Socket Handling
  },
  beforeUnmount() {
    this.socket.disconnect();
  }
};
</script>

<style lang="scss">
body {
  background-color: var(--bluegray-300);
  margin: 0;
}

*:fullscreen,
:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: rgba(255, 255, 255, 0);
}

.menu-end {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

div.p-menubar.p-component.offline {
  border-top: 2px solid red;
}
#desktop {
  margin-top: 75px;
}
div.p-menubar.p-component {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
}

li:hover {
  text-decoration: none;
}
#app1 {
  height: 100vh;
}
</style>