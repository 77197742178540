<template>
  <ion-page>
    <div class="page" id="page-1">
      <div class="sidebar">
        <div class="left">
          <Panel class="growing_panel" header="Abwesend" id="persons_offsite" ref="offsite" >
            <div class="no-scrollbar" :style="{height: heightOffsite + 'px'}"  style="overflow: scroll">
              <availibility-control
                  v-for="person in getPersonsOffSite"
                  :person="person"
                  :key="person.id"
              />
            </div>
          </Panel>
        </div>
        <div class="right">
          <div class="panel">
            <Panel class="growing_panel" header="Anwesend" id="persons_onsite">
              <template #icons>
                <div class="filter-button">
                  <label for="filter-just-available"> Nur ohne Position</label>
                  <InputSwitch id="filter-just-available" v-model="onlyWithoutPosition"/>
                </div>
              </template>
                <div class="no-scrollbar" :style="{height: heightOnsite + 'px'}"  style="overflow: scroll">
                  <availibility-control
                    v-for="person in getPersonsByLocation('ON_SITE', onlyWithoutPosition)"
                    :person="person"
                    :key="person.id"
                  />
              </div>
            </Panel>
          </div>

          <div class="panel">
            <Panel class="growing_panel" header="Auf Anfahrt" id="persons_onapproach">
              <div class="no-scrollbar" :style="{height: heightApproach + 'px'}" style="overflow: scroll">
                <availibility-control
                    v-for="person in getPersonsOnApproach"
                    :person="person"
                    :key="person.id"
                />
              </div>
            </Panel>
          </div>
        </div>
      </div>

      <div class="main">
        <div class="vehicles">
          <div class="flex card-container">
            <Vehicle
                class="flex-grow-1 flex"
                v-for="truck in vehicles"
                :key="truck.name"
                :vehicle="truck"
            />
          </div>
        </div>
        <div class="special-locations">
          <Panel class="location growing_panel" header="Sportraum" id="sportraum">
            <div class="no-scrollbar" :style="{height: heightSportraum + 'px'}" style="overflow: scroll">
              <availibility-control
                  v-for="person in getPersonsSport"
                  :person="person"
                  :key="person.id"
              />
            </div>
          </Panel>
          <Panel class="location growing_panel" header="SiWa" id="siwa">
            <div class="no-scrollbar" :style="{height: heightSiWa + 'px'}" style="overflow: scroll">
              <availibility-control
                  v-for="person in getPersonsSiWa"
                  :person="person"
                  :key="person.id"
              />
            </div>
          </Panel>

          <!-- <gate class="growing_panel gate"
                v-for="ccu in ccu"
                :ccu="ccu"
                :key="ccu.id"
          /> -->

        </div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import AvailibilityControl from "../components/AvailibilityControl.vue";
import Vehicle from "../components/Vehicle.vue";
//import Gate from '../components/Gate.vue';
import {IonPage} from "@ionic/vue";

export default {
  name: "Page1",
  components: {
    Vehicle,
    AvailibilityControl,
    IonPage,
    //Gate,
  },
  data() {
    return {
      socket: {},
      interval: null,
      onlyWithoutPosition: false,
      heightOffsite: 500,
      heightOnsite: 0,
      heightApproach: 0,
      heightSportraum: 0,
      heightSiWa: 0,
    };
  },
  methods: {
    ...mapActions({
      getPersons: "persons/getPersons",
      getVehicles: "vehicle/getVehicles",
      assignPersonToPosition: "vehicle/assignPersonToPosition",
      clearPosition: "vehicle/clearPosition",
      ioUpdatePerson: "persons/ioUpdatePerson",
      ioDeletePerson: "persons/ioDeletePerson",
      ioUpdateVehicle: "vehicle/ioUpdateVehicle",
      socketIsOffline: "connection/socketIsOffline",
      socketIsOnline: "connection/socketIsOnline",
      checkIfReloadNeeded: "connection/checkIfReloadNeeded",
      getCCU: "ccu/getCCU",
    }),
    select(person) {
      let payload = {
        person: person,
        vehicle: "LF 10",
        position: "Maschinist",
      };
      this.assignPersonToPosition(payload);
    },
    clear(vehicle, position) {
      this.clearPosition({vehicle: vehicle, position: position});
    },
    calculateContainerHeight() {
      this.heightOffsite = document.querySelector(".sidebar").clientHeight - 50;
      this.heightOnsite = document.querySelector("#persons_onsite .p-panel-content").clientHeight;
      this.heightApproach = document.querySelector("#persons_onapproach .p-panel-content").clientHeight;
      this.heightSportraum = document.querySelector("#sportraum .p-panel-content").clientHeight;
      this.heightSiWa = document.querySelector("#siwa .p-panel-content").clientHeight;
      console.log(this.$refs.offsite);
    },
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      persons: (state) => state.persons.persons,
      connection: (state) => state.connection.socket,
      ccu: (state) => state.ccu.ccu,
    }),
    ...mapGetters({
      getPersonsByLocation: "persons/getPersonsByLocation",
      getPersonsOnSite: "persons/getPersonsOnSite",
      getPersonsOffSite: "persons/getPersonsOffSite",
      getPersonsOnApproach: "persons/getPersonsOnApproach",
      getPersonsSport: "persons/getPersonsSport",
      getPersonsSiWa: "persons/getPersonsSiWa"
    }),
  },
  mounted() {

    this.getPersons();
    this.getCCU();
    this.getVehicles();
    setTimeout(this.calculateContainerHeight, 120);
    // Does an only check.
    this.interval = setInterval(() => {
      this.checkIfReloadNeeded()
    }, 1000);

  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
.page {
  display: grid;
  height: calc(100% - 75px);
  grid-template-columns: 850px minmax(0, 4fr);
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas: "sidebar main";
}

.sidebar {
  grid-area: sidebar;
  padding: 16px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  gap: 16px 16px;
  grid-template-areas: "left right";

  .left {
    grid-area: left;
  }

  .right {
    display: grid;
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    align-content: space-between;
    grid-row-gap: 16px;

    .panel {
      flex-grow: 2;

      &:first-child {
        margin-bottom: 16px;
      }

    }
  }
}

.main {
  overflow: scroll;
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-content: space-between;
  gap: 16px 16px;
  grid-template-areas:
    "vehicles"
    "special-locations";
}

.vehicles {
  grid-area: vehicles;
  overflow-x: scroll;
}

.special-locations {
  grid-area: special-locations;
  grid-area: special-locations;
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 16px;
  justify-content: flex-start;
  align-content: stretch;
  min-height: 40vh;

  .location {
    flex-grow: 1;
    max-width: 350px;
    margin: 0 0.5rem 0 0.5rem;
  }

  .gate {
    max-width: 165px;
  }
}

li {
  margin-top: 16px;
}

.filter-button {
  display: flex;

  label {
    margin-right: 0.5rem;
  }
}

li:hover {
  cursor: pointer;
  text-decoration: underline;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
.growing_panel {
  &.p-panel {
    height: 100%;
    display: flex;
    flex-direction: column;

    .p-toggleable-content {
      flex-grow: 1;

      .p-panel-content {
        height: 100%
      }
    }

  }
}

</style>