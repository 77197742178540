import axios from "axios";
import AuthService from "@/services/auth.service";

const initialState = {
    vehicles: []
}

export const vehicle = {
    namespaced: true,
    state: initialState,
    getters: {
        getAllVehicles: (state) => {
            return state.vehicles;
        },
        getVehicleByName: (state) => (name) => {
            return state.vehicles.find(vehicle => vehicle.name === name)
        },
        getPostionOfPerson: (state) => (personName) => {
            let currentPosition = {
                vehicle: {},
                position: {},
                iPosition: -1,
                iVehicle: -1,
            }
            vehicleLoop:
            for (let iVehicle = 0; iVehicle < state.vehicles.length; iVehicle++) {
                for (let iPos = 0; iPos < state.vehicles[iVehicle].positions.length; iPos++) {
                    if (state.vehicles[iVehicle].positions[iPos].person && 
                        state.vehicles[iVehicle].positions[iPos].person.name === personName
                        ) {
                        currentPosition.vehicle = state.vehicles[iVehicle]
                        currentPosition.position = state.vehicles[iVehicle].positions[iPos]
                        currentPosition.iVehicle = iVehicle;
                        currentPosition.iPosition = iPos
                        break vehicleLoop;
                    }
                }
            }
            return currentPosition

        },
    },
    actions: {
        async getVehicles(state) {
            try {
                const {data} = await axios.get(`${process.env.VUE_APP_API_URL}/vehicles?_sort=order:ASC`);
                state.commit('addVehicles', data)
            } catch (error) {
                if (error.response.status == 401) {
                    AuthService.logout()
                    this.router.push('/login');
                }
                if (error.response.status == 403) {
                    console.error("Not able to get vehicles. Maybe not Logged in?");
                }
            }
        },
        async updateRemoteVehicle(state, vehicle) {
            return await axios.put(`${process.env.VUE_APP_API_URL}/vehicles/${vehicle.id}`, vehicle);
        },
        async notify(state, vehicle) {
            try {
                let response = await axios.post(`${process.env.VUE_APP_API_URL}/vehicles/${vehicle.id}/notify`, vehicle);
                this.$toast.add({severity:'success', summary: 'Benachrichtigung versendet', detail:'Die Einteilung wurde erfolgreich an die Mannschaft per aPager verendet', life: 3000});
                return await response 
            } catch(error) {
                this.$toast.add({severity:'error', summary: 'Benachrichtigung NICHT versendet', detail:'Es ist ein Fehler beim Versenden der Benachrichtigung aufgetreten', life: 3000});
                return error;
            }
             
        },
        /**
         * 
         * @param {*} state 
         * @param {*} payload {person: Person Object, position: <String> Positon on Vehicle, vehicle: <String> of vehicle Name}
         */
        assignPersonToPosition(state, payload) {
            let oldPosition = state.getters.getPostionOfPerson(payload.person.name)
            if (oldPosition.iPosition >= 0) {
                state.dispatch("clearPosition", oldPosition)
            }
            let vehicle = state.getters.getVehicleByName(payload.vehicle);
            let position = vehicle.positions.find(position => position.name === payload.position)
            position.person = payload.person;
            state.commit('updateVehicle', vehicle);
            state.dispatch('updateRemoteVehicle', vehicle);
        },
        clearAllPositions(state) {
            let vehicles = vehicle.state.vehicles;
            vehicles.forEach(vehicle => {
                
                vehicle.positions.forEach(position => {
                    // eslint-disable-next-line
                    position.person = {};
                });
                state.commit('updateVehicle', vehicle);
                state.dispatch('updateRemoteVehicle', vehicle);  
            });
        },
        /**
        * 
        * @param {*} state 
        * @param {*} payload {vehicle: vehicleObject, position: positionObject}
        */
        clearPosition(state, payload) {
            let vehicle = state.getters.getVehicleByName(payload.vehicle.name);
            let position = vehicle.positions.find(position => position.name === payload.position.name)
            position.person = {};
            state.commit('updateVehicle', vehicle);
            state.dispatch('updateRemoteVehicle', vehicle)  
        },
        ioUpdateVehicle(state, vehicle) {
            state.commit('updateVehicle', vehicle);
        }
    },
    mutations: {
        addVehicles(state, vehicles) {
            state.vehicles = vehicles;
        },
        updateVehicle(state, vehicle) {
            let iVehicle = state.vehicles.findIndex(v => v.id === vehicle.id);
            state.vehicles[iVehicle] = vehicle;
        },
    }
};