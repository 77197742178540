import './registerServiceWorker'
import * as Vue from 'vue'
import {router} from "./router";
import store from "./store/index"

import vSelect from 'vue-select'
import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import axios from 'axios'
import VueAxios from 'vue-axios'
import {IonicVue} from '@ionic/vue';

import App from "./App.vue";
import interceptorsSetup from './services/interceptor.service';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import ConfirmationService from 'primevue/confirmationservice';
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Card from "primevue/card";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import InputSwitch from 'primevue/inputswitch';
import InputText from "primevue/inputtext";
import Message from 'primevue/message';
import Panel from "primevue/panel";
import Password from "primevue/password";
import ScrollPanel from "primevue/scrollpanel";
import SplitButton from 'primevue/splitbutton';
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Toast from "primevue/toast";


import VueMobileDetection from "vue-mobile-detection";

const app = Vue.createApp(App);

app.use(VueMobileDetection);
app.config.devtools = true;
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Button", Button);
app.component("Card", Card);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Dialog", Dialog);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("Message", Message);
app.component("Panel", Panel);
app.component("Password", Password);
app.component("ScrollPanel", ScrollPanel);
app.component("SplitButton", SplitButton);
app.component("TabPanel", TabPanel);
app.component("TabView", TabView);
app.component("Toast", Toast);
app.component('v-select', vSelect);


app.use(ToastService);

interceptorsSetup();
app.use(ConfirmationService);

interceptorsSetup()



app.directive('badge', BadgeDirective);


app.use(store);
app.use(VueAxios, axios);
app.use(IonicVue);
app.use(PrimeVue, {ripple: true});

app.use(router);


router.isReady().then(() => {
    app.mount('#app');
});

